export default defineI18nConfig(() => ({
  legacy: false,
  locales: ['en', 'zn'],
  defaultLocale: 'en',
  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  vueI18n: {
    fallbackLocale: 'en',
  },
  vueI18nLoader: true,
}));
